export default {
  error: 'เกิดข้อผิดพลาด',
  unknown: 'เกิดข้อผิดพลาดที่ไม่รู้จัก',
  importError: 'ไม่พบรายการให้นำเข้า ใช้เทมเพลตการนำเข้าไม่ถูกต้องหรือไม่?',
  unableToDownloadData: 'ไม่สามารถดาวน์โหลดข้อมูลที่ร้องขอได้',
  jobFailed: 'เกิดข้อผิดพลาดในการประมวลผลไฟล์',
  wrongInput: 'ข้อมูลที่ให้มาไม่ถูกต้อง!',
  nameExist: 'ชื่อที่ให้มามีอยู่แล้ว!',
  numberExist: 'หมายเลขที่ให้มามีอยู่แล้ว!',
  passwordsMustBeEqual: 'รหัสผ่านต้องเหมือนกัน!',
  toShortPassword: 'รหัสผ่านต้องมีอักขระอย่างน้อย {length} ตัว!',
  descriptionIsRequired: 'จำเป็นต้องระบุรายละเอียด',
  reasonRequired: 'สร้างหรือเลือกเหตุผลก่อน',
  criticalPleaseRefresh: 'อ๊ะสแน็ป! เกิดข้อผิดพลาด โปรดลองรีเฟรชหน้าเว็บ',
  invalidHour: 'ชั่วโมงไม่ถูกต้อง',
  importNotAvailable: 'ไม่สามารถนำเข้าได้ในขณะนี้ โปรดลองอีกครั้งในภายหลัง',
  packageAddError: 'แพ็คเกจไม่ถูกต้อง',
  code: {
    NoRequiredComponents: 'ขาดส่วนประกอบที่จำเป็น:',
    NoTrackingId: 'ไม่มีหมายเลขติดตามสำหรับการติดตามแพ็คเกจ'
  },
  invalidAuthUrl: 'URL การตรวจสอบไม่ถูกต้อง',
  invalidValue: 'ค่าไม่ถูกต้อง',
};
