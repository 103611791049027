export default {
  error: '错误',
  unknown: '未知错误',
  jobFailed: '处理文件时出错',
  wrongInput: '给定的数据无效！',
  passwordsMustBeEqual: '密码必须相同！',
  toShortPassword: '密码必须至少包含 {length} 个字符',
  descriptionIsRequired: '需要说明',
  reasonRequired: '首先创建或选择原因',
  criticalPleaseRefresh: '啊！ 出现错误，尝试刷新页面',
  invalidHour: '无效小时',
  importNotAvailable: '目前无法导入。 请稍后再试',
  packageAddError: '无效数据',
  code: { NoRequiredComponents: '缺少必需的组件：', NoTrackingId: '没有用于跟踪包装的跟踪码' },
  invalidValue: '无效值',
};
