export default {
  'report': 'Rapport',
  'new': 'Nieuw',
  'sum': 'Optellen',
  'time': 'Tijdsduur',
  'quantity': 'Hoeveelheid',
  'aggregated': 'Samengevoegd',
  'detailed': 'Gedetailleerd',
  'showNAlarms': 'Toon <b>{0}</b> meest langdurige alarmen',
  'showOthers': 'Toon overige',
  'hideOthers': 'Verberg overige',
  'sumOfSmaller': 'Som van overig',
  'dropHereToCreateNew': 'Sleep en laat los om een nieuwe grafiek te creëren',
  'completedOrders': 'Afgeronde orders',
  'history': 'Historie',
  'usedEnergy': 'Gebruikte energie',
  'addPersonal': 'Persoonlijk dashboard',
  'addFactory': 'Fabriek dashboard',
  'work': 'Werk',
  'plannedDowntime': 'Gepland',
  'unplannedDowntime': 'Ongepland',
  'expected': 'Verwacht',
  'rootElement': 'Hoofd element',
  'performanceImpact': 'Prestatie verlies',
  'availabilityImpact': 'Beschikbaarheid verlies',
  'minorStops': 'Minor stops',
  'oeeLoss': {
    'oeeLoss': '{0} verlies',
    'productionLoss': 'Productie verlies',
    'undefinedIssue': 'Type probleem ongedefinieerd',
    'utilizationLosses': 'Gebruiksverliezen',
    'availabilityLosses': 'Beschikbaarheid verliezen',
    'performanceLosses': 'Prestatie verliezen',
    'qualityLosses': 'Kwaliteitsverliezen',
    'speedLoss': 'Snelheidsverlies',
    'qualityLoss': 'Kwaliteitsverlies',
    'speedIncrease': 'Verhoogde snelheid winst',
    'combined': 'Gecombineerd',
    'byMachine': 'Verdeel per lijn/machine',
    'byProblem': 'Verdeel per problemen',
    'byState': 'Verdeel per status',
    'base': 'Standaard tijd',
    'time': {
      'totalTime': 'Totale tijd',
      'scheduledTime': 'Geplande tijd',
      'operatingTime': 'Operationele tijd',
      'productionTime': 'Productie tijd',
      'effectiveTime': 'Effectieve tijd'
    },
    'performanceStops': 'Prestatieverlies stilstanden',
    'qualityStops': 'Kwaliteitsverlies stilstanden',
    'calculatedBasedOnTotalAvailabilityLoss': 'Berekend op basis van het totale beschikbaarheidsverlies'
  },
  'select': {
    'allLines': 'Alle lijnen',
    'allMachines': 'Alle machines',
    'selectMachine': 'Selecteer machine',
    'selectLine': 'Selecteer lijn',
    'clear': 'Leegmaken',
    'selectLineAreaOrPlant': 'Selecteer fabriek, lijn of gebied'
  },
  'alarms': {
    'description': 'Beschrijving',
    'numberOfOccurances': 'Aantal',
    'duration': 'Tijdsduur',
    'sumOfSmallerAlarms': 'Totaal aan kleine alarmen',
    'noDataInThisPeriod': 'Geen data in deze periode'
  },
  'selectors': {
    'period': 'Periode',
    'moment': 'Moment',
    'dateTo': 'Datum naar',
    'dateFrom': 'Datum van',
    'shift': 'Dienst',
    'day': 'Dag',
    'week': 'Week',
    'month': 'Maand',
    'today': 'Vandaag',
    'yesterday': 'Gisteren',
    'thisWeek': 'Deze week',
    'previousWeek': 'Vorige week',
    'thisMonth': 'Deze maand',
    'previousMonth': 'Vorige maand',
    'selectLineAndMachine': 'Selecteer lijn en machine',
    'selectLine': 'Selecteer lijn',
    'selectLineOrMachine': 'Selecteer lijn of machine',
    'timeFilter': 'Tijd filter',
    'lastThreeMonths': 'Laatste 3 maanden',
    'selectExactDates': 'Selecteer exacte data',
    'selectDatesFromList': 'Geselecteerde data van de lijst',
    'addElement': 'Fabriek toevoegen aan rapport',
    'amount': 'Hoeveelheid'
  },
  'types': {
    'custom': 'Op maat gemaakt',
    'availability': 'Beschikbaarheid',
    'efficiency': 'Efficientie',
    'oee': 'OEE',
    'oeeLosses': 'OEE verliezen',
    'production': 'Productie',
    'productionMap': 'Productie kaart',
    'downtimes': 'Stilstanden',
    'rootcauses': 'Stilstand hoofdoorzaken',
    'alarms': 'Alarmen',
    'column': 'Kolom',
    'pie': 'Taart',
    'operatorStops': 'Stilstand door operator',
    'downtimeDuration': 'Stilstand tijdsduur',
    'downtimeDurations': 'Stilstand tijdsduren',
    'reasons': 'Redenen',
    correctiveActions: 'Correctieve acties',
    'energy': 'Stroom',
    'energySankey': 'Sankey diagram',
    'energyHistory': 'Gebruik historie',
    'heatmap': 'Heatmap diagram',
    'commentsFromLosses': 'Opmerkingen van verliezen',
    'oeeDetails': 'OEE details',
    'reportsDownload': 'Rapport download',
    'typeOfLosses': 'Type verliezen'
  },
  'operatorStop': {
    'waiting': 'Wachten op actie',
    'fixing': 'Tijdsduur actie',
    'stopsBecauseOf': 'Stop omdat',
    'timeSum': 'Totale tijdsduur'
  },
  'rootcause': {
    'impact': {
      'title': 'Impact',
      'desc': '(Effectieve tijdsduur / Root cause tijdsduur)'
    }, 'effectDuration': 'Effectieve tijdsduur', 'rootcauseDuration': 'Tijdsduur root cause'
  },
  'chart': {'toggleFullView': 'Activeer volledige modus'},
  'showNLongest': 'Laat langste <b>{0}</b> zien',
  'splitBy': 'Verdelen',
  'unitConversionNotComplete': 'Eenheidsconversie niet compleet',
  'unitConversionComplete': 'Eenheidsconversie compleet',
  'unableToFullyConvertUnitMessage': 'We kunnen niet alle productie omzetten naar \'{unit}\', controleer de verpakkingsstructuur van SKU\'s die in deze periode zijn geproduceerd',
  'utilizationImpact': 'Gebruiksverlies',
  'minorStopsIncluded': 'Kleine stops (inbegrepen in totale duur)',
  'detailedEventsReport': 'Gedetailleerd gebeurtenissenrapport',
  'siteOeeReport': 'Site OEE-rapport',
  'speedLossReport': 'Snelheidsverlies rapport',
  'labourVarianceReport': 'Arbeidsafwijkings rapport',
  'siteWeeklyScorecard': 'Site wekelijkse scorekaart',
  'siteMonthlyScorecard': 'Site maandelijkse scorekaart',
  'exportReport': 'Exporteer rapport',
  'excludeUtilizationLoss': 'Neem gebruiksverlies niet mee',
  'chooseEvent': 'Selecteer probleem',
  'needToSelectEvent': 'Je dient een probleem te selecteren'
};
