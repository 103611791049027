export default {
  error: 'Ошибка',
  unknown: 'Неизвестная ошибка',
  jobFailed: 'При обработке файла произошла ошибка',
  wrongInput: 'Данные недействительны!',
  passwordsMustBeEqual: 'Пароли должны быть одинаковыми!',
  toShortPassword: 'Пароль должен содержать не менее {кол-во} символов.',
  descriptionIsRequired: 'Требуется описание',
  reasonRequired: 'Сначала создайте или выберите причину',
  criticalPleaseRefresh: 'Ой! Произошла ошибка, попробуйте обновить страницу',
  invalidHour: 'Недействительный час',
  importNotAvailable: 'Импорт в настоящее время недоступен. Пожалуйста, попробуйте позже',
  packageAddError: 'Неверная упаковка',
  code: {
    NoRequiredComponents: 'Отсутствие необходимых компонентов:',
    NoTrackingId: 'Нет идентификатора отслеживания для отслеживания пачек'
  },
  invalidValue: 'Недопустимое значение',
};
