export default {
  'error': 'Foutmelding',
  'unknown': 'Onbekende fout',
  'jobFailed': 'Er was een fout in het verwerken van het bestand',
  'wrongInput': 'De gegeven waarden zijn ongeldig',
  'passwordsMustBeEqual': 'Wachtwoorden moeten hetzelfde zijn',
  'toShortPassword': 'Wachtwoord moet minimaal {length} tekens zijn',
  'descriptionIsRequired': 'Beschrijving is vereist',
  'reasonRequired': 'Maak of selecteer eerst de oorzaak',
  'criticalPleaseRefresh': 'Wat vervelend! Er ging iets fout, probeer de pagina opnieuw te laden',
  'invalidHour': 'Ongeldig uur',
  'importNotAvailable': 'Importeer functie is momenteel niet beschikbaar. Probeer het later nog een keer',
  'packageAddError': 'Ongeldig pakket',
  'code': {
    'NoRequiredComponents': 'Gebrek aan benodigde onderdelen',
    'NoTrackingId': 'Geen volg ID voor volg pakketen'
  },
  'importError': 'Geen artikelen gevonden om te importeren. Verkeerde import template?',
  'unableToDownloadData': 'Niet mogelijk om de gevraagde data te downloaden',
  'nameExist': 'Gekozen naam bestaat al',
  'numberExist': 'Gekozen nummer bestaat al',
  'invalidAuthUrl': 'Onjuiste auth url',
  invalidValue: 'Ongeldige waarde',
};
