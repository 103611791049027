export default {
  error: 'Błąd',
  unknown: 'Nieznany błąd',
  importError: 'Nie znaleziono żadnych elementów do zaimportowania. Zły szablon importu?',
  unableToDownloadData: 'Nie możemy pobrać danych',
  jobFailed: 'Wystąpił błąd w trakcie przetwarzania pliku',
  wrongInput: 'Podane dane są nieprawidłowe!',
  nameExist: 'Podana nazwa już istnieje!',
  numberExist: 'Podany numer już istnieje!',
  passwordsMustBeEqual: 'Hasła muszą być identyczne!',
  toShortPassword: 'Hasło musi zawierać conajmniej {length} znaków',
  descriptionIsRequired: 'Podanie opisu jest wymagane',
  reasonRequired: 'Wybierz lub utwórz przyczynę',
  criticalPleaseRefresh: 'Kurza twarz! Wystąpił błąd, spróbuj odświeżyć stronę',
  invalidHour: 'Niewłaściwa godzina',
  importNotAvailable: 'Import nie jest aktualnie możliwy. Spróbuj ponownie później.',
  packageAddError: 'Nieprawidłowe opakowanie',
  invalidAuthUrl: 'Niewłaściwy URL do autoryzacji',
  code: {
    NoRequiredComponents: 'Brak wymaganych komponentów:',
    NoTrackingId: 'Brak kodu serii dla śledzonych paczek',
  },
  manualProductionNoLongerThan15Days: 'Nie możesz edytować manualnej produkcji dla okresów dłuższych niż 15 dni',
  invalidValue: 'Nieprawidłowa wartość',
};
