export default {
  'error': 'Lỗi',
  'unknown': 'Lỗi chưa xác định',
  'jobFailed': 'Có lỗi trong việc xử lý tệp',
  'wrongInput': 'Dữ liệu không hợp lệ!',
  'passwordsMustBeEqual': 'Mật khẩu phải bằng nhau!',
  'toShortPassword': 'Mật khẩu phải có ít nhất {chiều dài}  ký tự',
  'descriptionIsRequired': 'Mô tả là bắt buộc',
  'reasonRequired': 'Tạo hoặc chọn lý do trước',
  'criticalPleaseRefresh': 'Rất tiếc! Đã xảy ra lỗi, hãy thử làm mới trang',
  'invalidHour': 'Giờ không hợp lệ',
  'importNotAvailable': 'Tính năng nhập hiện không khả dụng. Vui lòng thử lại sau',
  'packageAddError': 'Gói không hợp lệ',
  'code': {
    'NoRequiredComponents': 'Thiếu các thành phần cần thiết:',
    'NoTrackingId': 'Không có id theo dõi cho các gói theo dõi'
  },
  'importError': 'Không tìm thấy mục nào để nhập. Mẫu nhập sai?',
  'unableToDownloadData': 'Không thể tải xuống dữ liệu được yêu cầu',
  'nameExist': 'Tên đã tồn tại!',
  'numberExist': 'Số đã tồn tại!',
  'invalidAuthUrl': 'url xác thực không hợp lệ',
  invalidValue: 'Giá trị không hợp lệ',
};
