export default {
  'error': 'Error',
  'unknown': 'Error desconocido',
  'unableToDownloadData': 'No se pueden descargar los datos solicitados',
  'jobFailed': 'Hubo un error al procesar el archivo.',
  'wrongInput': '¡Los datos proporcionados no son válidos!',
  'passwordsMustBeEqual': '¡Las contraseñas deben ser iguales!',
  'toShortPassword': 'La contraseña debe tener como mínimo {longitud} caracteres',
  'descriptionIsRequired': 'Se requiere una descripción',
  'reasonRequired': 'Cree o seleccione el motivo primero',
  'criticalPleaseRefresh': '¡UPS! Hubo un error, intente actualizar la página',
  'invalidHour': 'Hora inválida',
  'importNotAvailable': 'La importación no está disponible actualmente. Por favor, inténtelo de nuevo más tarde',
  'packageAddError': 'Paquete inválido',
  'code': {
    'NoRequiredComponents': 'Falta de componentes necesarios',
    'NoTrackingId': 'Sin identificación de seguimiento para paquetes de seguimiento'
  },
  'invalidAuthUrl': 'URL no valida',
  'importError': 'No se encontraron items para importar. ¿Documento errado a importar?',
  'nameExist': 'El nombre suministrado ya existe!',
  'numberExist': 'El numero suministrado ya existe!',
  invalidValue: 'Valor inválido',
};
