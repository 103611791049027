export default {
  'error': 'Fehler',
  'unknown': 'unbekannter Fehler',
  'unableToDownloadData': 'Download der angefragten Daten nicht möglich',
  'jobFailed': 'Beim Verarbeiten der Datei ist ein Fehler aufgetreten',
  'wrongInput': 'Angegebene Daten sind ungültig!',
  'passwordsMustBeEqual': 'Passwörter müssen gleich sein!',
  'toShortPassword': 'Das Passwort muss mindestens {length} Zeichen haben',
  'descriptionIsRequired': 'Beschreibung ist erforderlich',
  'reasonRequired': 'Zuerst einen Grund angeben oder auswählen',
  'criticalPleaseRefresh': 'Es ist ein Fehler aufgetreten. Aktualisieren Sie die Seite.',
  'invalidHour': 'ungültige Stunde',
  'importNotAvailable': 'Import ist derzeit nicht verfügbar. Bitte versuchen Sie es später noch einmal',
  'packageAddError': 'Ungültiges Paket',
  'code': {
    'NoRequiredComponents': 'Mangel an benötigten Komponenten:',
    'NoTrackingId': 'Keine Tracking-ID zum Nachverfolgen von Paketen'
  },
  'invalidAuthUrl': 'Ungültige Authentifizierungs-URL',
  'importError': 'Es wurden keine zu importierenden Artikel gefunden. Falsche Importvorlage?',
  'nameExist': 'Name existiert bereits!',
  'numberExist': 'Nummer existiert bereits!',
  invalidValue: 'Ungültiger Wert',
};
