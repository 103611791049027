export default {
  'report': 'Reporte',
  'new': 'Nuevo',
  'sum': 'Suma',
  'time': 'Tiempo',
  'quantity': 'Tiempo',
  'aggregated': 'Agregado',
  'detailed': 'Detallado',
  'showNAlarms': 'Mostrar <b>{0}</b> alarmas más largas',
  'showNLongest': 'Show <b>{0}</b> longest',
  'showOthers': 'Mostrar otros',
  'hideOthers': 'Esconder otros',
  'sumOfSmaller': 'Suma de otros',
  'dropHereToCreateNew': 'Arrastre y suelte aquí para crear un nuevo gráfico',
  'completedOrders': 'Ordenes completadas',
  'history': 'Historia',
  'usedEnergy': 'Energía usada',
  'addPersonal': 'Panel de control personal',
  'addFactory': 'Panel de control de fábrica',
  'unitConversionNotComplete': 'Unidades de conversión no completas',
  'unitConversionComplete': 'Unidades de conversión completas',
  'unableToFullyConvertUnitMessage': 'No podemos convertir toda laproducción a \'{Unidad}\', verifique la esctructura de empaque de los productos que se produjeron durente este periodo',
  'work': 'Trabajo',
  'plannedDowntime': 'Planificado',
  'unplannedDowntime': 'No planificado',
  'expected': 'Esperado',
  'rootElement': 'Elemento raíz',
  'performanceImpact': 'Pérdida de rendimiento',
  'availabilityImpact': 'Pérdida de disponibilidad',
  'utilizationImpact': 'Perdidas de utilización',
  'minorStops': 'Paradas menores',
  'minorStopsIncluded': 'Paradas menores (Incluidas en la duración total)',
  'oeeLoss': {
    'oeeLoss': 'Pérdida {0}',
    'productionLoss': 'Pérdida de producción',
    'undefinedIssue': 'Tipo de problema indefinido',
    'utilizationLosses': 'Pérdidas de utilización',
    'availabilityLosses': 'Pérdida de disponibilidad',
    'performanceLosses': 'Pérdidas de rendimiento',
    'qualityLosses': 'Pérdidas de calidad',
    'speedLoss': 'Pérdida de velocidad',
    'qualityLoss': 'Pérdida de calidad',
    'speedIncrease': 'Aumento de velocidad de ganancia',
    'combined': 'Combinado',
    'byMachine': 'Dividida por línea / máquina',
    'byProblem': 'Dividido por problema',
    'byState': 'Dividido por estado',
    'base': 'Tiempo base',
    'time': {
      'totalTime': 'Tiempo Total',
      'scheduledTime': 'Hora programada',
      'operatingTime': 'Tiempo de funcionamiento',
      'productionTime': 'Tiempo de producción',
      'effectiveTime': 'Tiempo efectivo'
    },
    'performanceStops': 'Paradas por perdidas en performance',
    'qualityStops': 'Paradas por perdidas en calidad',
    'calculatedBasedOnTotalAvailabilityLoss': 'Cálculo basado en la perdida total de Disponibilidad'
  },
  'select': {
    'allLines': 'Todas las lineas',
    'allMachines': 'Todas las maquinas',
    'selectMachine': 'Seleccionar maquina',
    'selectLine': 'Seleccionar línea',
    'clear': 'Limpiar',
    'selectLineAreaOrPlant': 'Seleccionar Planta, línea o área'
  },
  'alarms': {
    'description': 'Descripción',
    'numberOfOccurances': 'Número',
    'duration': 'Duración',
    'sumOfSmallerAlarms': 'Suma de alarmas más pequeñas',
    'noDataInThisPeriod': 'No hay datos en este período'
  },
  'selectors': {
    'period': 'Período',
    'moment': 'Momento',
    'dateTo': 'Fecha hasta',
    'dateFrom': 'Fecha desde',
    'shift': 'Cambio',
    'day': 'Dia',
    'week': 'Semana',
    'month': 'Mes',
    'today': 'Hoy',
    'yesterday': 'Ayer',
    'thisWeek': 'Esta semana',
    'previousWeek': 'Semana pasada',
    'thisMonth': 'Este mes',
    'previousMonth': 'Mes anterior',
    'selectLineAndMachine': 'Seleccionar línea y máquina',
    'selectLine': 'Seleccionar línea',
    'selectLineOrMachine': 'Seleccionar línea o máquina',
    'timeFilter': 'Filtro de tiempo',
    'lastThreeMonths': 'Últimos 3 meses',
    'selectExactDates': 'Seleccionar fechas exactas',
    'selectDatesFromList': 'Seleccionar fechas de la lista',
    'addElement': 'Agregar elemento de fábrica para informar',
    'amount': 'Cantidad'
  },
  'types': {
    'custom': 'Personalizado',
    'availability': 'Disponibilidad',
    'efficiency': 'Eficiencia',
    'oee': 'OEE',
    'oeeLosses': 'Pérdidas OEE',
    'oeeDetails': 'Detalles de OEE',
    'production': 'Producción',
    'productionMap': 'Mapa de producción',
    'downtimes': 'Tiempos de inactividad',
    'rootcauses': 'Causas principales del tiempo de inactividad',
    'alarms': 'Alarmas',
    'column': 'Columna',
    'pie': 'Torta',
    'operatorStops': 'Paradas de operador',
    'downtimeDuration': 'Duración del tiempo de inactividad',
    'downtimeDurations': 'Duraciones del tiempo de inactividad',
    'reasons': 'Razones',
    correctiveActions: 'Acciones correctivas',
    'energy': 'Energía',
    'energySankey': 'Sankey',
    'energyHistory': 'Historial de uso',
    'heatmap': 'Mapa de calor',
    'commentsFromLosses': 'Comentarios de pérdidas',
    'typeOfLosses': 'Tipos de perdidas',
    'reportsDownload': 'Descarga de reportes'
  },
  'operatorStop': {
    'waiting': 'Esperando una acción',
    'fixing': 'Duración de la acción',
    'stopsBecauseOf': 'Se detiene debido a',
    'timeSum': 'Duración total'
  },
  'rootcause': {
    'impact': {
      'title': 'Impact',
      'desc': '(Duración del efecto / Duración de la causa raíz)'
    }, 'effectDuration': 'Duración del efecto', 'rootcauseDuration': 'Duración de la causa raíz'
  },
  'chart': {'toggleFullView': 'Alternar vista completa'},
  'chooseEvent': 'Seleccionar problemas',
  'needToSelectEvent': 'Se necesita seleccionar el problema',
  'splitBy': 'Separe por',
  'detailedEventsReport': 'Reportes de eventos detallados',
  'siteOeeReport': 'Reporte de OEE del Site',
  'speedLossReport': 'Reporte de Perdida de Velocidad',
  'labourVarianceReport': 'Reporte de variación de mano de obra',
  'siteWeeklyScorecard': 'Scorecard Semanal del site',
  'siteMonthlyScorecard': 'Scorecard Mensual del site',
  'exportReport': 'Exportar reporte',
  'excludeUtilizationLoss': 'Excluir perdida de utilización'
};
